<template>
<div class="card card-custom" v-loading="loading">
        <div class="card-body p-0">
            <!--begin: Orders-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Order Body-->
                <div class="row justify-content-center ">
                    <div class="col-xl-12">                     
                        <!--begin: Orders Form-->
                        <el-form ref="form" :model="form" auto-complete="false">
                           <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">                          
                                  <el-row>
                                        <el-col :span="12" align="left" v-if="view.website_details">
                                          <span v-loadimage="view.website_details.website_logo" ></span>                                           
                                        </el-col>
                                        <el-col :span="12" align="right">
                                             <b >
                                        <label class="mt-10">{{view.website_details.address}}, {{view.website_details.city_details.city_name}}</label><br>                                      
                                        <label>{{view.website_details.state_details.state_name}} - {{view.website_details.post_code}}, {{view.website_details.country}}.</label><br>
                                        <label>Phone: {{view.website_details.phone}}</label><br>
                                        <label>Fax: {{view.website_details.fax}}</label><br>
                                        <label>Email: {{view.website_details.email_address}}</label>
                                        </b>
                                        <br>
                                        </el-col>
                                  </el-row>
                                  <hr class="invoice-line">
                                  <el-row>                                    
                                      <el-col :span="24" align="center">
                                      <h2 style="font-family: Arial;text-align: center;"><b>DELIVERY ORDER DETAILS</b></h2>
                                      </el-col>                                                                            
                                  </el-row>
                                  <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>ORDER : </b></span><span><b> {{view.order_number}} {{ view.backorder_id ? ' - BO':'' }}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>Delivery ID : </b></span><span><b>{{view.id}}</b></span>
                                       </el-col>
                                       <el-col :span="12"  align="left">
                                           <span v-if="view.packing_id"><b>PACKING SLIP ID : </b></span><span><b>{{view.packing_id}}</b></span>
                                       </el-col>
                                  </el-row>
                                    <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO : </div>
                                          <div style="padding: 3px" v-if="view.customer.company_name">{{view.customer.company_name}}</div>
                                          <div style="padding:3px" v-if="view.customer"><span v-if="view.customer.first_name">{{view.customer.first_name}}</span> <span v-if="view.customer.last_name">{{view.customer.last_name}}</span></div>
                                          <div style="padding: 3px;" ><span v-if="view.billing_address_order.address_line_2">{{view.billing_address_order.address_line_2}}</span><span v-if="view.billing_address_order.address_line_1">, Unit No : {{view.billing_address_order.address_line_1 }}</span></div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.city_details">{{view.billing_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.state_details">{{view.billing_address_order.state_details.state_name}}<span v-if="view.billing_address_order.post_code"> - {{view.billing_address_order.post_code}}</span></div>
                                      </el-col>
                                      <el-col :span="8" align="left" :offset="8" v-if="view.shipping_address_order">
                                         <div class="billing-adr" style="padding-left:10px;">SHIP TO :</div>
                                         <div style="padding: 3px" v-if="view.customer.company_name">{{view.customer.company_name}}</div>
                                         <div style="padding:3px" v-if="view.customer">{{view.customer?view.customer.first_name+' '+(view.customer.last_name==null?'':view.customer.last_name):''}}</div>
                                          <div style="padding: 3px;" ><span v-if="view.shipping_address_order.address_line_2">{{view.shipping_address_order.address_line_2}}</span><span v-if="view.shipping_address_order.address_line_1">, Unit No : {{view.shipping_address_order.address_line_1 }}</span></div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.city_details">{{view.shipping_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.state_details">{{view.shipping_address_order.state_details.state_name}}<span v-if="view.shipping_address_order.post_code"> - {{view.shipping_address_order.post_code}}</span></div>
                                      </el-col>
                                    </el-row>
                             <div class="row">
                              <div class="col-xl-12"><h4>Delivery Items</h4>                                
                                
                              </div>
                           </div>
                            <el-form-item  v-if="form.order_id" prop="products">
                             
                              <el-table :data="form.products" stripe border fit >                                                         
                                  <el-table-column
                                    type="index"
                                    width="50">                                    
                                  </el-table-column>
                                  <el-table-column label="Item #" width="100">
                                       <template slot-scope="scope">
                                         <span>{{form.products[scope.$index].product.sku}}</span>                                                                                  
                                      </template>
                                  </el-table-column>
                                  <el-table-column label="Product Name" >                                     
                                       <template slot-scope="scope">
                                         <span>{{form.products[scope.$index].product.name}}</span>                                         
                                      </template>
                                  </el-table-column>
                                   <el-table-column label="Type" width="100">
                                     <template slot-scope="scope"> 
                                       <span>{{form.products[scope.$index].product.unit}}</span>      
                                      </template>
                                  </el-table-column>
                                   <el-table-column label="Quantity" width="150" align="center">
                                      <template slot-scope="scope">
                                        <span>{{form.products[scope.$index].quantity}}</span>                                         
                                      </template>
                                  </el-table-column>
                                   <el-table-column label="Backorder Qty" width="150" align="center">
                                      <template slot-scope="scope">
                                        <span>{{form.products[scope.$index].back_quantity}}</span>                                         
                                      </template>
                                  </el-table-column>      
                                  <el-table-column label="Delivery Quantity" width="150" align="center">
                                      <template slot-scope="scope">
                                        <span><b>{{form.products[scope.$index].delivery_quantity}}</b></span>                                         
                                      </template>
                                  </el-table-column>     
                                      
                                </el-table>                             
                           </el-form-item> 
                            </div>
                        </el-form>                     
                    </div>
                </div>
            </div>
        </div>
</div>
  
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services';
import { globalEvents } from '@/utils/globalEvents';
import {getAllCustomers} from "@/api/customer";
import {getCustomerOrder, getOrderList} from "@/api/packingslip";
import {searchCustomer,getCustomerAddress} from "@/api/order";
import { getProductsForDropdown} from "@/api/product";
import CreateAddress from '@/components/CreateAddress/addAddress';
export default {
   name: 'add-PackingSlip',
    data() {      
    return {
      loading:false,     
      error: null,
      bill : false,
      ship : false,
      defaultDate: new Date('2018-06-22'),
       form : new Form({
         customer_id: null,
         order_id: null,
         id:null,
         ship_date: new Date(),
         billing_address_id: null,
         shipping_address_id : null,
         total_weight:null,
         total_quantity:null,
         ship_via:null,
         type:null,
         comments: null,
         order_number:null,
         products: [
           {
             product_id: null,                       
             quantity: null,            
             product_weight:null, 
             delivery_quantity:null,           
             shipped: 0,
             net_weight:0,
             back_order:null,
             product_item: [{
               sku: null,
               name:null,
               type:null
             }]            
           }
         ]
         
       }),      
       submit_btn_text: 'Create Packing Slip',
       title_text: 'Delivery Order Details',     
       customer_list:[],
       order_list:[],      
       addresses:[],
       product_list: [],
       billing_address:[],
       shipping_address:[],
       view:[],
       api_url : 'delivery/packingslip/create'
       
    }
    },
     mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
     
      { title: "Delivery Orders" }
    ]);
    globalEvents.$on('AddressAdded', added => { 
      this.shipping_address = [];        
        if (added) {     
          this.addresses.push(added);         
          if(added.type == 'billing'){
            this.form.billing_address_id = added.id
            this.billing_address.push(added);
            this.bill = false;
          }else{
            this.form.shipping_address_id = added.id
            this.shipping_address.push(added);
            this.ship = false;
          }
        }        
      });
     },
     methods: {
       isExist(product_id){
      for(var i=0; i < this.form.products.length; i++){
        if( this.form.products[i].product_id == product_id){
          return true
        }
      }
      return false
    },

      getAllCustomers(){
      this.loading = true
      getAllCustomers().then(response => {        
        this.customer_list = response.data.data.data
        this.loading = false
       
      })   
    },
    getCustomerOrder(){      
        this.loading = true
        this.billing_address = [];
        this.shipping_address = [];
        this.form.products = [];
        this.form.order_id = '';
        this.form.total_weight = '';
        this.form.total_quantity = '';
        this.form.computed = '';
      getCustomerOrder(this.form.customer_id).then(response => {    
        this.order_list = response.data.data;      
        this.loading = false
       
      })  
       getCustomerAddress(this.form.customer_id).then(response => {                   
            this.loading = false
            this.addresses = response.data.data;
          }); 
    },
   
    getOrderItems(){
       this.loading = true;
       this.billing_address = [];
       this.shipping_address = [];
        this.form.products = [];
      getOrderList(this.form.order_id).then(response => {  
           
       this.form.billing_address_id = response.data.data.billing_address_order.id; 
       this.form.shipping_address_id = response.data.data.shipping_address_order.id;
       this.form.ship_via = response.data.data.ship_via;
       this.form.type = response.data.data.type;
       this.form.products = response.data.data.order_items;
       this.billing_address.push(response.data.data.billing_address_order);
       this.shipping_address.push(response.data.data.shipping_address_order);         
       this.loading = false
      })   

    },
     getAllproductlists(){
      this.loading = true
      getProductsForDropdown().then(response => {
        this.product_list = response.data.data       
        this.loading = false
      })
    },   
   createAddress(val){      
      var titleName = 'Shipping Address';
      if(val == '1'){
        titleName = 'Billing Address'
      }
     this.$modal.show(CreateAddress, {
          customer_id: this.form.customer_id,
          address_type : val,         
          label:{button:'Create',title:titleName}
        }, 
        { draggable: true, transition: 'pop-out', overflow:'auto', height: 'auto', clickToClose: false, adaptive: true, width: '600px'})
    },
   
    addressLabel(address){
      let formattedAddress = '';
      if(address.company_name){
        formattedAddress += address.company_name + ', ';
      }
      if(address.address_line_1){
        formattedAddress += address.address_line_1 + ', ';
      }
      if(address.address_line_2){
        formattedAddress += address.address_line_2 + ', ';
      }
      if(address.city){
        formattedAddress += address.city_details.city_name + ', ';
      }
      if(address.state){
        formattedAddress += address.state_details.state_name + ', ';
      }
      if(address.post_code){
        formattedAddress += address.post_code + ', ';
      }
      return formattedAddress;
    },
    setProductWeight(index){
       let pid = this.form.products[index].product_id;              
       let shipping = parseInt(this.form.products[index].shipped);
      this.form.products[index].product_weight = shipping*this.getProductWeight(pid);    
      this.form.products[index].net_weight = this.getProductWeight(pid);      
      this.calculateTotal();
    },
     getProductWeight(product_id){
      let productWeight = 0;
      if(product_id){
        this.product_list.map((product) =>{
          
          if(product_id == product.id){
            productWeight = product.weight;
          }
        })
      }     
      return productWeight;      
    },
    calculateTotal() {
      
        var quantity_total = this.form.products.reduce(function (sum, product) {             
                var quantity = parseFloat(product.shipped);
                if (!isNaN(quantity)) {                 
                    return sum + quantity;
                }else{                    
                  return sum;
                }
            }, 0);
        
          var weightTotal = this.form.products.reduce(function (sum, product) {             
                var ProductTotal = parseFloat(product.product_weight);
                if (!isNaN(ProductTotal)) {                 
                    return sum + ProductTotal;
                }else{                    
                  return sum;
                }
            }, 0);
             this.form.total_weight = weightTotal; 
             this.form.total_quantity = quantity_total;     
      },
       createPackingslip: function() {                
      var event = new Date(this.form.ship_date);
      let date = JSON.stringify(event);
      this.form.ship_date = date.slice(1,11);
      this.form.post(this.api_url).then((response) => {
        if(response.status){
            this.$router.push({ path: '/packingslip/list' });
            this.loading=false;
            this.$showResponse('success', response.message);
        }
      }).catch((error) => {
				  this.loading = false;
	        	console.log(error)
	      	})
    }, 
     addShipping(){
      let shipping_id = this.form.shipping_address_id;
      this.shipping_address = [];
      this.addresses.map((billing)=>{
        if(billing.type == 'shipping'){
          if(shipping_id == billing.id){
            this.shipping_address.push(billing);
            this.ship = false;
          }
        }
      });
    }
     
     },
      watch: { 
      search(value) {
        if (!value) {
          return
        }         
        this.loading = true      
        searchCustomer(value).then(response => {        
          this.customer_list = response.data.data         
          this.loading = false
        })
      },  
     
  },
     created(){
       
       let orderId = this.$route.params.order_id;  
        
     
    if(parseInt(orderId) > 0){ 
          
       getOrderList(orderId).then(response => {        
          this.form.customer_id = response.data.data.customer_id;     
          getCustomerOrder(this.form.customer_id).then(response => {    
          this.order_list = response.data.data;      
          this.loading = false
       
      })  
       getCustomerAddress(this.form.customer_id).then(response => {                   
          this.loading = false
          this.addresses = response.data.data;
          });
          this.form.id = parseInt(orderId); 
          this.form.order_id = parseInt(response.data.data.order_id);  
          this.form.billing_address_id = response.data.data.billing_address_order.id; 
          if(response.data.data.shipping_address_order != null){
            this.form.shipping_address_id = response.data.data.shipping_address_order.id;
          } 
          this.view = response.data.data;
          this.form.ship_via = response.data.data.ship_via;
          this.form.type = response.data.data.type;
          this.form.products = response.data.data.order_items; 
          this.billing_address.push(response.data.data.billing_address_order);
          if(response.data.data.shipping_address_order != null){
            this.shipping_address.push(response.data.data.shipping_address_order);  
          } 
       
           
      
      
      });
    }       
       this.getAllCustomers();
       this.getAllproductlists();
      
     },
      computed :{
      shippingAddresses() {
          return this.addresses.filter(addr => {
            return addr.type == 'shipping'
          });
      },
     
  },


  
}
</script>

<style >

  .billing-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
.default-logo img{
    width: 125px;
    margin-top: 30px;
    margin-left: 10px;
}
</style>